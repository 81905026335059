// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  display: flex;
  flex-direction:row;
  justify-content: center;
  padding: 0;
  background-color: #121212;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #E0E0E0;
  text-align: center;
}

.menu h3 {
  margin: 10 0px;
  color: #BB86FC; /* Accent color */
  font-weight: 400;
}

.h3menu {
  cursor: pointer;
}

.menu button {
  background: #BB86FC; /* Accent color */
  color: #121212; /* Dark text */
  border: none;
  padding: 8px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 16px;
  font-weight: 500;
}

.menu button:hover {
  background: #9e77d9; /* Darkened accent color on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Optionally, style the 'Toggle Favorites' section if it is interactive */
.toggle-favorites {
  margin: 10px 0;
  cursor: pointer;
}

.toggle-favorites:hover {
  color: #9e77d9;
}`, "",{"version":3,"sources":["webpack://./src/components/Menu.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,UAAU;EACV,yBAAyB;EACzB,wCAAwC;EACxC,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,cAAc,EAAE,iBAAiB;EACjC,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB,EAAE,iBAAiB;EACtC,cAAc,EAAE,cAAc;EAC9B,YAAY;EACZ,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,2DAA2D;EAC3D,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB,EAAE,mCAAmC;EACxD,2BAA2B,EAAE,uBAAuB;AACtD;;AAEA,0EAA0E;AAC1E;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".menu {\n  display: flex;\n  flex-direction:row;\n  justify-content: center;\n  padding: 0;\n  background-color: #121212;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n  color: #E0E0E0;\n  text-align: center;\n}\n\n.menu h3 {\n  margin: 10 0px;\n  color: #BB86FC; /* Accent color */\n  font-weight: 400;\n}\n\n.h3menu {\n  cursor: pointer;\n}\n\n.menu button {\n  background: #BB86FC; /* Accent color */\n  color: #121212; /* Dark text */\n  border: none;\n  padding: 8px 20px;\n  margin: 0 10px;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease, transform 0.2s ease;\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.menu button:hover {\n  background: #9e77d9; /* Darkened accent color on hover */\n  transform: translateY(-2px); /* Slight lift effect */\n}\n\n/* Optionally, style the 'Toggle Favorites' section if it is interactive */\n.toggle-favorites {\n  margin: 10px 0;\n  cursor: pointer;\n}\n\n.toggle-favorites:hover {\n  color: #9e77d9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
