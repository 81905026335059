import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Menu.css';

const Menu = ({ showNSFW, toggleNSFWVisibility, showFavoritesOnly, toggleShowFavorites }) => {
    const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

    return (
        <div className="menu">
            {isAuthenticated && user ? (
                <>
                    <h3>Welcome, {user.given_name} &nbsp;|&nbsp;&nbsp; </h3>
                    <h3 className="h3menu" onClick={toggleShowFavorites}> Favorites &nbsp;|&nbsp;&nbsp; </h3>
                    <h3 className='h3menu' onClick={toggleNSFWVisibility}>{showNSFW ? "Hide NSFW" : "Show NSFW"} &nbsp;|&nbsp;&nbsp; </h3>
                    <h3 className="h3menu" onClick={() => logout({ returnTo: window.location.origin })}>
                      Logout
                    </h3>
                </>
            ) : (
                <>
                    <button onClick={loginWithRedirect}>Login / Register</button>
                </>
            )}
        </div>
    );
};
  
export default Menu;
