import React, { useState } from 'react'; // Import useState here
import './App.css';
import Header from './components/Header';
import ImageGrid from './components/ImageGrid';
import Favs from './components/Favs';
import Menu from './components/Menu';

function App() {
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const [showNSFW, setShowNSFW] = useState(false);

  const toggleNSFWVisibility = () => {
    setShowNSFW(!showNSFW);
};

  const toggleFavorites = () => {
    setShowFavoritesOnly(prevState => {
      return !prevState;
    });
  };

  return (
    <div className="App">
        <Header />
        <Menu 
    showFavoritesOnly={showFavoritesOnly} 
    toggleShowFavorites={toggleFavorites} 
    showNSFW={showNSFW} 
    toggleNSFWVisibility={toggleNSFWVisibility} // Check if toggleNSFWVisibility is passed
/>
<ImageGrid 
  key={showFavoritesOnly} 
  showFavoritesOnly={showFavoritesOnly} 
  showNSFW={showNSFW} 
  toggleNSFWVisibility={toggleNSFWVisibility} // Pass down toggleNSFWVisibility
/>
        <Favs />
    </div>
  );
}

export default App;