import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Header.css';

const Header = () => {
  const { isAuthenticated, user, } = useAuth0();

  return (
    <header className="header">
      <h1>LATEST MUSCLE</h1>
      {isAuthenticated && user ? (
        <>
        </>
      ) : (
        <p></p>
      )}
    </header>
  );
  
};
export default Header;
