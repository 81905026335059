// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  background: #121212; /* Slightly lighter dark tone for header */
  color: #E0E0E0;
  padding: 0;
  text-align: center;
}
  
.header h1 {
  margin: auto;
}

  `, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAE,0CAA0C;EAC/D,cAAc;EACd,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":["header {\n  background: #121212; /* Slightly lighter dark tone for header */\n  color: #E0E0E0;\n  padding: 0;\n  text-align: center;\n}\n  \n.header h1 {\n  margin: auto;\n}\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
