// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base */
body {
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #121212; /* Dark background */
    color: #E0E0E0; /* Light text */
    line-height: 1.6;
}

a {
    color: #BB86FC; /* Accent color */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Container for centering the content */
.container {
    width: 80%;
    margin: auto;
    overflow: hidden;
}

/* Image Grid */
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 15px;
    align-items: stretch;
}

.image-container {
    position: relative;
}

/* Heart Icon for Favorites */
.heart-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: rgba(255, 255, 255, 0.7); /* Subtle white */
}

.heart-icon.favorite {
    color: #ff4569; /* Favorite heart color */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,SAAS;AACT;IACI,SAAS;IACT,4DAA4D;IAC5D,yBAAyB,EAAE,oBAAoB;IAC/C,cAAc,EAAE,eAAe;IAC/B,gBAAgB;AACpB;;AAEA;IACI,cAAc,EAAE,iBAAiB;IACjC,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA,wCAAwC;AACxC;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;AACpB;;AAEA,eAAe;AACf;IACI,aAAa;IACb,2DAA2D;IAC3D,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;AACtB;;AAEA,6BAA6B;AAC7B;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,+BAA+B,EAAE,iBAAiB;AACtD;;AAEA;IACI,cAAc,EAAE,yBAAyB;AAC7C","sourcesContent":["/* Base */\nbody {\n    margin: 0;\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n    background-color: #121212; /* Dark background */\n    color: #E0E0E0; /* Light text */\n    line-height: 1.6;\n}\n\na {\n    color: #BB86FC; /* Accent color */\n    text-decoration: none;\n}\n\na:hover {\n    text-decoration: underline;\n}\n\n/* Container for centering the content */\n.container {\n    width: 80%;\n    margin: auto;\n    overflow: hidden;\n}\n\n/* Image Grid */\n.image-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n    grid-gap: 15px;\n    align-items: stretch;\n}\n\n.image-container {\n    position: relative;\n}\n\n/* Heart Icon for Favorites */\n.heart-icon {\n    position: absolute;\n    bottom: 10px;\n    right: 10px;\n    color: rgba(255, 255, 255, 0.7); /* Subtle white */\n}\n\n.heart-icon.favorite {\n    color: #ff4569; /* Favorite heart color */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
