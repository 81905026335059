import React from 'react';
import { createRoot } from 'react-dom/client';
import './Index.css'; // Global CSS
import App from './App'; // Import App component
import reportWebVitals from './reportWebVitals'; // Tool for measuring performance
import { Auth0Provider } from '@auth0/auth0-react';

const container = document.getElementById('root');
const root = createRoot(container); // Create a root
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

root.render(
  <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    // Update here for redirectUri deprecation
    authorizationParams={{ redirect_uri: window.location.origin }}>
    <App />
  </Auth0Provider>
  // Removed the extra document.getElementById('root')
);

// Optional: can be used for logging performance metrics
reportWebVitals();
