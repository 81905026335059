import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const getFavorites = async (userId) => {
  try {
    const response = await fetch(`https://api.latestmuscle.com/favorites/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Include authorization headers if needed
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const favorites = await response.json();
    return favorites;
  } catch (error) {
    console.error('Error retrieving favorites:', error);
  }
};

const Favs = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const saveUserToDatabase = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
                    await fetch('https://api.latestmuscle.com/api/user', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ auth0Id: user.sub })
          });
          
        } catch (error) {
          console.error('Error saving user:', error);
        }
      }
    };

    saveUserToDatabase();
  }, [isAuthenticated, user, getAccessTokenSilently]);

useEffect(() => {
  if (isAuthenticated && user) {
    handleAuthentication(user); // To ensure user is in the database
    getFavorites(user.sub) // Assuming user.sub is the userId
      .then(favorites => {
        // Handle the retrieved favorites, e.g., set them in the state
      });
  }
}, [isAuthenticated, user, getAccessTokenSilently]);

const handleAuthentication = async (user) => {
  try {
    const token = await getAccessTokenSilently();
    await fetch('https://api.latestmuscle.com/api/user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ auth0Id: user.sub })
    });
  } catch (error) {
    console.error('Error saving user:', error);
  }
};

return (
  <div>
    
  </div>
);
};

export default Favs;
